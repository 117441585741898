<template>
  <div class="add_teacher">
    <div class="title">添加/编辑行程</div>
    <br />
    <hr />
    <br />
    <el-card class="box-card">
      <el-form
        ref="addFromRef"
        :model="addFrom"
        :rules="addFromRules"
        label-width="110px"
      >
        <el-form-item label="标题:" prop="title">
          <el-input v-model="addFrom.title" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="行程说明:" prop="summary">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="addFrom.summary"
            style="width: 50%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="入住酒店:">
          <el-input v-model="addFrom.hotelName" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="轮播图">
          <send-image
            type="more"
            @addImageStrs="addImageStrs"
            :images="addFrom.hotelImg"
          ></send-image>

          <i>每张图片不能超过500K，建议尺寸：80*45px</i>
        </el-form-item>
        <el-form-item label="用餐情况">
          <el-input
            v-model="addFrom.meals"
            type="textarea"
            :rows="2"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="用车情况">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="addFrom.daysCar"
            style="width: 50%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="酒店详情:">
          <VueUeditorWrap
            v-model="addFrom.hotelDetails"
            :config="editorConfig"
            ref="myTextEditor"
          ></VueUeditorWrap>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import editorConfig from "../../utils/editorConfig.js";
import VueUeditorWrap from "vue-ueditor-wrap";
import sendImage from "../../components/sendImage.vue";
import { tripListAdd, tripListDetails } from "../../api/travel.js";
export default {
  components: { VueUeditorWrap, sendImage },
  data() {
    return {
      addFrom: {
        daysId: 0,
        travelId: 0,
        title: "",
        summary: "",
        hotelName: "",
        hotelImg: "",
        meals: "",
        daysCar: "",
        hotelDetails: "",
      },
      editorConfig,
      addFromRules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        summary: [{ required: true, message: "请输入行程说明", trigger: "blur" }],
      },
    };
  },
  created() {
    this.addFrom.daysId = this.$route.query.daysId || 0;
    this.addFrom.travelId = this.$route.query.travelId || 0;
    if (this.addFrom.daysId) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      tripListDetails({ daysId: this.addFrom.daysId }).then((res) => {
        if (res.data.code == 0) {
          this.addFrom = res.data.data;

          this.addFrom.hotelImg = this.addFrom.hotelImg.join(",");
          console.log(this.checkList);
        }
      });
    },
    handleAvatarSuccess(res, file, type) {
      this.addFrom.headImgUrl = file.response.data[0].src;
    },
    addImageStrs(arr) {
      this.addFrom.hotelImg = arr.join(",");
    },
    submitForm() {
      console.log(this.checkList);
      const params = {
        ...this.addFrom,
      };
      //   console.log(params);
      //   return;
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;

        this.isSubmit = true;
        const { data } = await tripListAdd(params);
        this.isSubmit = false;
        if (data.code == 0) {
          this.goBack();
          this.$message.success(data.msg);
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
